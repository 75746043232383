import tabImg1 from "../../assets/images/scale.png";
import tabImg2 from "../../assets/images/scale.png";

export const tabsContent = [
  {
    title: "Long",
    text: "Bet on assets. Ride the market up. Loop through for leverage.",
    img: tabImg1
  },
  {
    title: "Short",
    text: "Bearish on assets? Short them and  profit when the market downturns.",
    img: tabImg2
  }
]
