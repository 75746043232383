import Button from "../../components/Button/Button";
import React from "react";
import ImgCards from "../../components/ImgCards/ImgCards";
import Tabs from "../../components/Tabs/Tabs";
import Title from "../../components/Title/Title";

import "./Home.scss";

import videoThumbnail from "../../assets/images/video-thumbnail.webp";
import logo from "../../assets/images/fungify-bartio-top.svg";
import tradeProfitWin from "../../assets/trade-profit-win.svg";
import starDecorationL from "../../assets/star-decoration-l.svg";
import starDecorationR from "../../assets/star-decoration-r.svg";
import console from "../../assets/console.svg";
import waysToWinTxt from "../../assets/images/ways-to-win-header.svg";
import overviewTxt from "../../assets/images/overview-header.svg";
import leaderboard from "../../assets/images/leaders.svg";

const Home = () => {
  return (
    <div className="home">
      <div className="hero section">
        <Title
          leftIcon={starDecorationL}
          rightIcon={starDecorationR}
          hideMobileIcons={true}
          customClass="hero"
        >
          <img className="hero-logo" src={logo} alt="" />
          <img className="hero-text" src={tradeProfitWin} alt="" />
          <p className="hero-subtitle">
            Use the protocol. Make money. Win prizes.
          </p>
        </Title>
        <div className="banner">
          <img src={videoThumbnail} alt="" />
          {/* <video autoPlay muted loop poster={banner}>
          <source src="/video/compressed.mp4" type="video/mp4"/>
        </video> */}
        </div>
      </div>

      <div className="sign-up section">
        <div className="console-decoration">
          <img src={console} alt="" />
          <a
            href="https://discord.gg/fungify"
            style={{ textDecoration: "none" }}
          >
            <Button size="big">Apply on Discord</Button>
          </a>
        </div>
      </div>

      <div className="image-cards section">
        <ImgCards />
      </div>

      <div className="divider divider-middle"></div>

      <div className="ways-to-win section">
        <Title customClass="ways-to-win">
          <img src={waysToWinTxt} alt="" />
        </Title>

        <div className="txt block">
          <p>Your goal is simple: have the largest portfolio.</p>
          <p>Make degen moves, be an arbitragooor, or index yield farmer.</p>
          <p>The choice is yours. Here are some tools Fungify unlocks:</p>
        </div>

        <div className="tabs block">
          <Tabs />
        </div>
      </div>

      {/* <div className="divider divider-small"></div>

      <div className="join section">
        <div className="overview-r">
          <img src={yourTeamIsWaiting} alt="your team is waiting" />
        </div>
        <div className="overview-l">
          <img src={vs1} alt="" />
        </div>
      </div> */}

      <div className="divider"></div>

      <div className="overview section">
        <div className="overview-l">
          <img src={leaderboard} alt="" />
        </div>
        <div className="overview-r">
          <img src={overviewTxt} alt="" />
          <div className="overview-txt">
            <p>The competition will be based on your overall PnL. Rise in the ranks to win rewards and prizes.</p>
          </div>
          <div className="overview-buttons-wrapper">
            <a
              className="button-like-link"
              href="https://discord.gg/fungify"
              style={{
                borderRadius: "24px",
                width: "188px",
                padding: "11px 0",
              }}
            >
              Apply on Discord
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
