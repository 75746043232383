import nftIndex from "../../assets/images/prize-nft-index.svg";
import honey from "../../assets/images/prize-honey.svg";

export const imageCards = [
  {
    title: "Honey Comb NFT",
    img: honey
  },
  {
    title: "$NFT Index",
    img: nftIndex
  }
]
